import { fetchUrbaAuthenticationUrl } from './requests';

export async function getUrbaneaseAuthentication(
  email: string,
  lng?: number,
  lat?: number,
  department?: string
) {
  try {
    const result: any = await fetchUrbaAuthenticationUrl(
      email,
      lng,
      lat,
      department
    );

    if (result.url) {
      window.open(result.url, '_blank');
    }
    return 200;
  } catch (error: any) {
    return Promise.reject(error);
    //   dispatch(modalsActions.noUrbaneaseAccount.open(error.status));
  }
}
