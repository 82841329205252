import axios from 'axios';

const PROVIDER_NAME = process.env.REACT_APP_AUTH_PROVIDER_NAME ?? '';
const PROVIDER_KEY = process.env.REACT_APP_AUTH_PROVIDER_SECRET_KEY ?? '';
const API_DNS = process.env.REACT_APP_URBANEASE_API;

type BodyType = {
  user_email: string;
  auth_provider_name: string;
  auth_provider_secret_key: string;
  optional_params?: {
    plot_to_display: {
      latitude: number;
      longitude: number;
      departement_code: string;
    };
  };
};

export const fetchUrbaAuthenticationUrl = async (
  email: string | null,
  lng?: number | null,
  lat?: number | null,
  department?: string | null
) => {
  try {
    if (email) {
      const body: BodyType = {
        user_email: email,
        auth_provider_name: PROVIDER_NAME,
        auth_provider_secret_key: PROVIDER_KEY,
      };

      if (lng && lat && department) {
        body.optional_params = {
          plot_to_display: {
            latitude: lat,
            longitude: lng,
            departement_code: department,
          },
        };
      }

      const result: any = await axios.post(
        API_DNS + '/external_authentication',
        body
      );

      return result.data;
    } else {
      return Promise.reject({ status: 422, message: 'user email is missing' });
    }
  } catch (error: any) {
    if (error.response) {
      return Promise.reject({
        status: error.response.status,
        message: error.response.data,
      });
    }
  }
};
